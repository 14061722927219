import {graphql, useStaticQuery} from 'gatsby'

const useSettings = (lang = 'en_GB') => {
	const {allSanitySiteSettings} = useStaticQuery(
		graphql`
      query {
        allSanitySiteSettings {
          nodes {
            title
            i18n_lang
            logo {
              asset {
                url
              }
            }
            address_image {
              asset {
                url
                width
                height
              }
            }
            address {
              title
              street
              city
              state
              zip
              country
            }
            email
            phone
            social {
              platform
              url
            }
          }
        }
      }
		`)
	const settings = allSanitySiteSettings.nodes.find(node => node.i18n_lang === lang)
	if (!settings) {
		console.error('No settings found!', lang)
	}
	return settings
}

export default useSettings
