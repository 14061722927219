import {Box, GridItem, Heading, Link, SimpleGrid, Text} from '@chakra-ui/react'
import React, {useState} from 'react'
import {ModuleSerializer} from './index'
import {Stickybox} from './UI/atoms'
import {useHomepage} from '../hooks'
import FadeIn from './UI/atoms/FadeIn'
import {graphql, useStaticQuery} from 'gatsby'
import responsiveFontSize from '../@chakra-ui/gatsby-plugin/responsiveFontSize'

const Team = () => {
	const {
		team_title,
		team_intro,
		_rawTeamDefaultText,
		_rawTeamDescription
	} = useHomepage()
	const {team_members} = useStaticQuery(
		graphql`
      query {
        team_members: allSanityTeam {
          nodes {
            title
            _rawDescription
          }
        }
      }`
	)
	const [tabIndex, setTabIndex] = useState(-1)
	return <SimpleGrid columns={[1, 1, 1, 1, 5]} gap={responsiveFontSize(87)} textAlign={'justify'} id={'about'}>
		<GridItem colSpan={[1, 1, 1, 1, 2]}>
			<Stickybox fontSize={responsiveFontSize(30)} lineHeight={responsiveFontSize(44)} maxH={'80vh'} display={'flex'}
								 flexDirection={'column'}>
				<div>
					<FadeIn delayOrder={0.25}>
						<Heading fontSize={responsiveFontSize(100)} lineHeight={responsiveFontSize(145)} w={'full'}
										 mb={responsiveFontSize(42)}>{team_title}</Heading>
					</FadeIn>
					<FadeIn>
						<Text as={Link} href={'#'} fontFamily={'recoleta'} fontWeight={'bold'}
									onClick={(e) => {
										e.preventDefault()
										setTabIndex(-1)
									}}>Shampoo </Text>
						<Text as={'span'}>{team_intro} </Text>
						{team_members && team_members.nodes.map((member, index) => <React.Fragment key={'teamnode' + index}>
							{index !== 0 && <Text as={'span'}> and </Text>}
							<Link
								href={'#' + member.title}
								key={member.title}
								fontFamily={'recoleta'}
								fontWeight={'bold'}
								className={tabIndex === index ? 'underline active' : 'underline'}
								whiteSpace={'nowrap'}
								onClick={(e) => {
									e.preventDefault()
									setTabIndex(index)
								}}
							>{member.title}</Link>
						</React.Fragment>)}
					</FadeIn>
					<FadeIn delayOrder={1.25}>
						<Box fontSize={responsiveFontSize(22)} lineHeight={responsiveFontSize(33)} mt={responsiveFontSize(42)}>
							<ModuleSerializer blocks={_rawTeamDescription}/>
						</Box>
					</FadeIn>
				</div>
			</Stickybox>
		</GridItem>
		<GridItem colSpan={[1, 1, 1, 1, 3]} fontSize={responsiveFontSize(30)} lineHeight={responsiveFontSize(44)}
							pt={responsiveFontSize(30)}>
			{tabIndex === -1 && <FadeIn>
				<ModuleSerializer blocks={_rawTeamDefaultText}/>
			</FadeIn>}
			{team_members && team_members.nodes.filter((member, index) => index === tabIndex).map((member, index) => <FadeIn
				key={member.title + 'title'} id={member.title}>
				<ModuleSerializer blocks={member._rawDescription}/>
			</FadeIn>)}
		</GridItem>
	</SimpleGrid>
}

export default Team