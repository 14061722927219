import {Box, Flex, HStack, Link, SimpleGrid} from '@chakra-ui/react'
import React from 'react'
import {BackToTop, Logo, ViewportBox} from '../components/UI/atoms'
import {useSettings} from '../hooks'
import FadeIn from '../components/UI/atoms/FadeIn'
import responsiveFontSize from '../@chakra-ui/gatsby-plugin/responsiveFontSize'
import {ImagePopover} from '../components'

const Footer = () => {
	const {logo, title, email, address_image, address, phone} = useSettings()
	return <ViewportBox
		as={'footer'}
		sx={{
			display: 'flex',
			flexDirection: 'column',
			height: '100vh',
			py: responsiveFontSize(33)
		}}
	>
		<SimpleGrid columns={[1, 1, 1, 1, 3]} gap={[responsiveFontSize(33)]} fontSize={responsiveFontSize(30)}
								lineHeight={responsiveFontSize(40)}>
			<FadeIn delayOrder={0.2}>
				<ImagePopover image={address_image} href={'https://goo.gl/maps/hA9KD5FnbiCrwamG8'}>
					<Flex flexDirection={'column'}>
						<Box as={'span'} textTransform={'uppercase'}>{address.street}</Box>
						<Box as={'span'} textTransform={'uppercase'}>{address.city}, {address.zip}</Box>
					</Flex>
				</ImagePopover>
			</FadeIn>
			{email && <FadeIn delayOrder={0.4}>
				<Flex flexDirection={'column'} alignItems={'start'}>
					<Box as={'span'} textTransform={'uppercase'}>Get in Touch</Box>
					<Link href={'mailto:' + email} textTransform={'uppercase'}>{email}</Link>
				</Flex>
			</FadeIn>}
			{phone && <FadeIn delayOrder={0.6}>
				<Flex flexDirection={'column'} alignItems={'start'}>
					<Box as={'span'} textTransform={'uppercase'}>Call Us</Box>
					<Link href={'tel:' + phone.replaceAll(' ', '')}>{phone}</Link>
				</Flex>
			</FadeIn>}
		</SimpleGrid>
		<Flex flex={1} alignItems={'center'} justifyContent={'end'} mr={'10%'}>
			<FadeIn delayOrder={0.8}>
				<BackToTop maxW={responsiveFontSize(228)}/>
			</FadeIn>
		</Flex>
		{logo && logo.asset && <FadeIn delayOrder={1}>
			<Flex>
				<Logo/>
			</Flex>
		</FadeIn>}
		<FadeIn delayOrder={1.2}>
			<HStack justifyContent={'space-between'} mt={'xl'} fontSize={responsiveFontSize(16)} fontFamily={'cf-asty-std'}>
				<span>{title} © 2022. All rights reserved.</span>
				<span>Designed by <Link href={'https://www.faze.gr/'} isExternal>Faze</Link>, Developed by <Link
					href={'https://spiritdigital.agency/'} isExternal>Spirit</Link>.</span>
			</HStack>
		</FadeIn>
	</ViewportBox>
}

export default Footer
