import {AnimatePresence, motion, useScroll, useSpring, useTransform} from 'framer-motion'
import {Box, Container, useBreakpointValue} from '@chakra-ui/react'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import Fonts from './Fonts'
import Footer from './Footer'
import Header from './Header'
import {PageContextProvider} from '../context/PageContext'

const Layout = ({pageContext, location, children}) => {
	const {scrollY} = useScroll()
	const scrollRef = useRef(null)
	const header = useRef(null)
	const [pageHeight, setPageHeight] = useState(0)
	const resizePageHeight = useCallback(entries => {
		for (let entry of entries) {
			setPageHeight(entry.contentRect.height - header.current?.getBoundingClientRect().height)
		}
	}, [header])
	useLayoutEffect(() => {
		const resizeObserver = new ResizeObserver(entries =>
			resizePageHeight(entries)
		)
		scrollRef && resizeObserver.observe(scrollRef.current)
		return () => resizeObserver.disconnect()
	}, [scrollRef, resizePageHeight])

	const shouldSmoothScroll = useBreakpointValue({
		base: false,
		lg: true
	})
	const transform = useTransform(scrollY, [0, pageHeight], [0, -pageHeight])
	const physics = {damping: 15, mass: 0.27, stiffness: 55} // easing of smooth scroll
	const spring = useSpring(transform, physics) // apply easing to the negative scroll value

	const [scrolledUp, setScrolledUp] = useState(false)
	const [lastScroll, setLastScroll] = useState(scrollY.get())
	useEffect(() => {
		return scrollY.onChange(currentScroll => {
			if (currentScroll === 0) {
				setScrolledUp(true)
			} else if (!scrolledUp && currentScroll <= lastScroll) {
				setScrolledUp(true)
			} else if (scrolledUp && currentScroll > lastScroll) {
				setScrolledUp(false)
			}
			setLastScroll(currentScroll)
		})
	}, [scrollY, scrolledUp, lastScroll])

	const transformScrollY = useTransform(scrollY, [0, pageHeight], [0, 1])
	const scrollYProgressSmooth = useSpring(transformScrollY, {damping: 50, stiffness: 200})
	const backgroundColor = useTransform(
		scrollYProgressSmooth,
		[0, 0.33, 0.66, 1],
		['#FFFFFF', '#EAF0D5', '#F1C6DB', '#98D3EB']
	)

	return <PageContextProvider value={{location, pageContext}}>
		<Fonts/>
		<AnimatePresence exitBeforeEnter>
			<Box as={motion.div} key="header" position={'sticky'} top={0} zIndex={5} ref={header}
					 pointerEvents={lastScroll > 0 && !scrolledUp ? 'none' : 'initial'}>
				<Container>
					<Header hidden={lastScroll > 0 && !scrolledUp}/>
				</Container>
			</Box>
			<Box
				as={motion.div}
				key="smooth-scroll"
				ref={scrollRef}
				style={{
					y: shouldSmoothScroll ? spring : transform,
					backgroundColor
				}}
				sx={{
					position: 'fixed',
					top: '0',
					left: '0',
					width: '100%',
					overflow: 'hidden',
					willChange: 'transform'
				}}
			>
				{header.current && <div style={{height: header.current?.getBoundingClientRect().height}}/>}
				<Container>
					{children}
					<Footer/>
				</Container>
			</Box>
			<div style={{height: pageHeight}}/>
		</AnimatePresence>
	</PageContextProvider>
}

export default Layout
