import {Image} from '@chakra-ui/react'
import React from 'react'
import urlBuilder from '@sanity/image-url'

const SanityImage = ({src, image, imageWidth, imageHeight, ...props}) => {
	const imageSource = src ?? image
	let builder = urlBuilder()
	.dataset(process.env.GATSBY_SANITY_DATASET)
	.projectId(process.env.GATSBY_SANITY_PROJECT_ID)
	.image(imageSource).fit('max').auto('format').quality(72)
	.maxWidth(1920).maxHeight(1920)
	if (imageWidth) {
		builder = builder.width(imageWidth)
	}
	if (imageHeight) {
		builder = builder.height(imageHeight)
	}
	return <React.Fragment>
		{imageSource && <Image
			src={builder.url()}
			objectFit={'contain'}
			width={'full'}
			loading={'lazy'}
			{...props}
		/>}
	</React.Fragment>
}

export default SanityImage
