import {Box, Flex, GridItem, Heading, SimpleGrid} from '@chakra-ui/react'
import React, {useRef} from 'react'
import {ModuleSerializer} from './index'
import {ArrowDown} from './UI/atoms'
import {useHomepage} from '../hooks'
import FadeIn from './UI/atoms/FadeIn'
import responsiveFontSize from '../@chakra-ui/gatsby-plugin/responsiveFontSize'

const Projects = () => {
	const {
		projects_title,
		_rawProjectsContent,
		_rawProjectsDescription,
		_rawProjectsOutro
	} = useHomepage()
	const sentinel = useRef()

	return <React.Fragment>
		<SimpleGrid columns={[1, 1, 1, 1, 5]} gap={responsiveFontSize(90)} id={'projects'}>
			<GridItem colSpan={[1, 1, 1, 1, 2]}>
				<FadeIn delayOrder={0.25}>
					<Heading fontSize={responsiveFontSize(100)} lineHeight={responsiveFontSize(145)} w={'full'}
									 mb={responsiveFontSize(42)}>{projects_title}</Heading>
				</FadeIn>
				<FadeIn>
					<Box fontSize={responsiveFontSize(22)} lineHeight={responsiveFontSize(33)} textAlign={'justify'}>
						<ModuleSerializer blocks={_rawProjectsDescription}/>
					</Box>
				</FadeIn>
			</GridItem>
			<GridItem
				textAlign={'justify'} fontSize={responsiveFontSize(30)} lineHeight={responsiveFontSize(44)}
				colSpan={[1, 1, 1, 1, 3]} pt={responsiveFontSize(30)}>
				<ModuleSerializer blocks={_rawProjectsContent}/>
			</GridItem>
		</SimpleGrid>
		<Flex justifyContent={'center'} flexDirection={'column'} minH={'100vh'}
					fontSize={{base: responsiveFontSize(35), sm: responsiveFontSize(30), md: responsiveFontSize(57)}}
					lineHeight={responsiveFontSize(75)}
					textAlign={'center'} my={responsiveFontSize(200)}>
			<ModuleSerializer blocks={_rawProjectsOutro}/>
			<Flex mt={responsiveFontSize(100)} alignItems={'center'} justifyContent={'center'}>
				<ArrowDown maxW={responsiveFontSize(185)} onClick={() => {
					if (window) {
						window.scrollTo({
							top: window.pageYOffset + sentinel.current.getBoundingClientRect().top,
							behavior: 'smooth'
						})
					}
				}} cursor={'pointer'}/>
			</Flex>
		</Flex>
		<Box ref={sentinel}/>
	</React.Fragment>
}

export default Projects