import {Box} from '@chakra-ui/react'
import React from 'react'
import { motion, useTime, useTransform } from "framer-motion"

const BackToTop = props => {
	const time = useTime()
	const rotate = useTransform(
		time,
		[0, 15000],
		[0, 360],
		{ clamp: false }
	)
	return <Box
		{...props}
		as={'svg'}
		width="237" height="229" viewBox="0 0 237 229" fill="none" xmlns="http://www.w3.org/2000/svg"
		_hover={{
			path: {
				fill: '#000'
			}
		}}
		cursor={'pointer'}
		onClick={()=>{
			if (window){
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
		}}
	>
		<path fillRule="evenodd" clipRule="evenodd" d="M121.702 171.298L121.702 69.1731L170.913 118.384L176.149 113.149L118 55L59.851 113.149L65.0866 118.384L114.298 69.1731L114.298 171.298L121.702 171.298Z" stroke="black" strokeWidth="2"/>
		<motion.g clipPath="url(#clip0_0_1)" style={{rotate}}>
			<path d="M34.2378 39.2522L35.974 37.4386C36.3343 37.0594 36.4326 36.6637 36.3015 36.2185C36.1705 35.7734 35.761 35.2128 35.0895 34.5039L32.6491 31.9154L33.681 30.8437L38.1359 35.1799L41.0022 32.1957L42.1651 33.3333L39.2988 36.3175L46.7184 43.5388C47.1442 43.951 47.5537 43.951 47.9631 43.5223L49.8139 41.6098L50.9768 42.7474L49.1424 44.6599C48.258 45.5667 47.5537 46.0119 47.0132 45.9624C46.4727 45.913 45.7848 45.5173 44.9822 44.7259L37.8903 37.8178L35.4335 40.3733L34.2706 39.2357L34.2378 39.2522Z" fill="black"/>
			<path d="M61.6884 33.8591L59.6083 31.1552C59.6083 31.8477 59.5264 32.5566 59.4281 33.3151C59.3135 34.057 59.0186 34.9802 58.5273 36.0519C58.0359 37.1236 57.3808 37.9809 56.5782 38.6074C55.5136 39.4482 54.4981 39.8275 53.5482 39.745C52.5982 39.6626 51.7957 39.2009 51.1405 38.3436C50.6983 37.7666 50.4199 37.1236 50.338 36.4311C50.2561 35.7387 50.2888 35.0792 50.4526 34.4691C50.6164 33.8591 50.9112 33.1832 51.3207 32.4577C51.7465 31.7323 52.1396 31.1058 52.5327 30.5947C52.9258 30.0836 53.4008 29.5065 53.9904 28.8965C54.58 28.2865 54.9895 27.8578 55.2516 27.6105C55.5136 27.3632 55.8248 27.0829 56.1851 26.7532C55.3662 25.7145 54.449 25.1045 53.4335 24.9066C52.4181 24.7088 51.2224 25.1539 49.8138 26.2421C48.5854 27.1983 47.8812 28.2205 47.7174 29.3087C47.5536 30.3968 47.8157 31.4025 48.5199 32.3258L46.8821 33.5953C45.8011 32.1939 45.4571 30.6771 45.8502 29.0614C46.2433 27.4456 47.2096 26.0442 48.7165 24.8407C49.6501 24.1153 50.5345 23.6042 51.3534 23.3074C52.1888 23.0106 52.9258 22.8952 53.5809 22.9447C54.2361 22.9941 54.8912 23.2249 55.5464 23.6206C56.2015 24.0163 56.7748 24.478 57.2498 24.9726C57.7411 25.4672 58.2652 26.1102 58.8549 26.8686L61.3772 30.1495C61.934 30.875 62.3599 31.2542 62.6711 31.3201C62.9823 31.3861 63.359 31.2377 63.8012 30.8914L64.1452 30.6112L64.7839 31.4355L61.6392 33.8921L61.6884 33.8591ZM58.6092 29.8692L57.1842 28.0227C52.7948 32.1774 51.2879 35.1451 52.6801 36.9422C53.4663 37.9644 54.5473 37.9149 55.9558 36.8268C56.873 36.1179 57.5446 35.0627 57.954 33.6613C58.3635 32.2599 58.5928 31.0069 58.6256 29.8692L58.6092 29.8692Z" fill="black"/>
			<path d="M71.1717 11.437L68.1089 19.4497L77.543 24.4947L75.3646 25.6818L67.3882 21.3292L65.9633 25.1048L68.3382 29.5068L66.5201 30.496L55.9723 10.9423L57.7903 9.95312L64.9642 23.2582L68.9606 12.6405L71.1553 11.437L71.1717 11.437Z" fill="black"/>
			<path d="M85.503 20.2594C86.8788 19.7813 87.9106 19.0723 88.5822 18.1491C89.2701 17.2258 89.5157 16.2695 89.352 15.2803L91.301 14.5878C91.6122 16.3025 91.2683 17.8028 90.2855 19.0723C89.2864 20.3418 87.8943 21.2981 86.109 21.9411C83.816 22.7654 81.7359 22.6995 79.8687 21.7597C78.0016 20.82 76.7076 19.3031 75.9706 17.1928C75.2336 15.0825 75.2827 13.0875 76.1344 11.1915C76.9861 9.29548 78.5584 7.94354 80.8514 7.11919C83.2427 6.26186 85.2737 6.27835 86.9607 7.13567C88.6313 7.993 89.8269 9.41089 90.5148 11.3893L90.8097 12.2302L78.0179 16.7641C78.6076 18.248 79.5412 19.3691 80.8351 20.111C82.129 20.8529 83.685 20.8859 85.5194 20.2429L85.503 20.2594ZM81.4247 8.80087C79.8032 9.37792 78.7058 10.2352 78.1162 11.4058C77.5266 12.5764 77.33 13.8459 77.543 15.2143L88.2546 11.4223C88.0089 10.8947 87.6977 10.4331 87.321 10.0044C86.9443 9.57576 86.4857 9.21305 85.9288 8.89979C85.3883 8.58654 84.7168 8.40518 83.9306 8.38869C83.1445 8.35572 82.3091 8.5041 81.4247 8.81736L81.4247 8.80087Z" fill="black"/>
			<path d="M119.358 15.6092L118.85 6.42592C118.768 4.94208 118.555 3.88691 118.211 3.2604C117.867 2.63389 117.31 2.33712 116.573 2.38658C115.656 2.43604 114.903 2.91417 114.346 3.80447C113.789 4.69477 113.543 5.98077 113.609 7.62948L114.067 15.8895L112.004 16.0049L111.496 6.82161C111.414 5.33777 111.201 4.2826 110.857 3.65609C110.513 3.02958 109.956 2.73281 109.219 2.78227C108.269 2.83173 107.532 3.32634 106.959 4.26611C106.402 5.20587 106.173 6.54133 106.271 8.30545L106.713 16.3017L104.65 16.4171L103.863 1.89197L105.632 1.79305L105.78 4.61234L106.075 4.61234C106.55 2.28766 107.778 1.0841 109.711 0.968691C110.726 0.91923 111.512 1.15005 112.069 1.69412C112.626 2.22171 113.019 3.06255 113.248 4.20016L113.429 4.20016C113.92 1.89197 115.132 0.671923 117.065 0.573001C118.408 0.507053 119.341 0.935717 119.915 1.85899C120.471 2.78227 120.815 4.26611 120.93 6.31051L121.438 15.4938L119.374 15.6092L119.358 15.6092Z" fill="black"/>
			<path d="M131.609 15.5428C133.05 15.7407 134.279 15.5758 135.31 15.0647C136.342 14.5536 136.997 13.8282 137.292 12.8555L139.34 13.1357C138.832 14.8009 137.865 15.988 136.408 16.664C134.95 17.3399 133.279 17.5543 131.38 17.307C128.972 16.9772 127.137 15.9715 125.909 14.2898C124.681 12.6081 124.206 10.6627 124.501 8.43691C124.795 6.22764 125.745 4.46352 127.367 3.16104C128.972 1.85856 130.986 1.38043 133.41 1.71018C135.916 2.05641 137.734 2.99617 138.832 4.52947C139.929 6.06277 140.355 7.87635 140.077 9.95372L139.962 10.844L126.515 9.03045C126.368 10.6297 126.695 12.0476 127.498 13.3006C128.317 14.5536 129.676 15.312 131.609 15.5593L131.609 15.5428ZM133.165 3.4743C131.461 3.24348 130.086 3.50727 129.037 4.28216C127.989 5.04057 127.236 6.09574 126.81 7.41471L138.062 8.93152C138.078 8.35447 138.013 7.79391 137.865 7.24984C137.718 6.70577 137.472 6.16169 137.128 5.61762C136.785 5.09003 136.277 4.62839 135.589 4.24919C134.901 3.86999 134.098 3.60619 133.165 3.49078L133.165 3.4743Z" fill="black"/>
			<path d="M161.074 1.44545L157.569 9.62305L156.537 11.2718L156.816 11.3872C156.816 11.3872 156.996 11.2058 157.143 11.0904C157.274 10.975 157.569 10.7771 158.028 10.4969C158.47 10.2331 158.945 10.0187 159.42 9.87035C159.895 9.72197 160.501 9.65602 161.238 9.68899C161.959 9.70548 162.696 9.88684 163.433 10.2001C165.234 10.9915 166.43 12.3269 167.003 14.2394C167.576 16.1519 167.429 18.1139 166.561 20.1253C165.677 22.1862 164.366 23.6371 162.614 24.5274C160.861 25.4012 159.027 25.4342 157.094 24.5933C156.39 24.2801 155.784 23.8844 155.276 23.3898C154.768 22.8952 154.408 22.4005 154.195 21.9224C153.982 21.4443 153.802 20.9662 153.704 20.521C153.589 20.0759 153.54 19.7131 153.556 19.4329L153.556 19.0042L153.278 18.8888L152.05 21.7575L150.428 21.0486L159.191 0.621093L161.091 1.44545L161.074 1.44545ZM158.142 11.9148C156.783 12.6732 155.784 13.7778 155.178 15.2122C154.555 16.6465 154.441 18.1304 154.834 19.6472C155.227 21.164 156.161 22.2522 157.651 22.9116C159.125 23.5546 160.517 23.4887 161.778 22.6973C163.056 21.9224 164.006 20.7848 164.645 19.3175C165.283 17.8501 165.431 16.3663 165.12 14.8989C164.808 13.4316 163.908 12.3764 162.434 11.7334C160.943 11.0904 159.502 11.1399 158.142 11.8983L158.142 11.9148Z" fill="black"/>
			<path d="M174.947 34.0409L176.798 31.1886C176.143 31.403 175.455 31.5678 174.718 31.7162C173.981 31.8646 173.031 31.8646 171.851 31.7492C170.672 31.6338 169.673 31.2876 168.821 30.727C167.691 29.9851 167.003 29.1442 166.758 28.221C166.528 27.2977 166.692 26.3744 167.282 25.4676C167.675 24.8576 168.183 24.396 168.805 24.0827C169.427 23.7695 170.05 23.5881 170.688 23.5386C171.327 23.4892 172.048 23.5386 172.867 23.7035C173.686 23.8684 174.406 24.0497 175.012 24.2476C175.618 24.4454 176.306 24.7257 177.076 25.0719C177.846 25.4182 178.386 25.682 178.698 25.8468C179.009 26.0117 179.386 26.226 179.811 26.4568C180.516 25.3357 180.794 24.2641 180.647 23.2254C180.499 22.2032 179.697 21.1975 178.206 20.2247C176.912 19.3674 175.717 19.0377 174.636 19.2355C173.555 19.4333 172.703 20.0104 172.081 20.9831L170.344 19.8455C171.311 18.3452 172.621 17.5208 174.275 17.3725C175.93 17.2076 177.551 17.6692 179.173 18.7244C180.155 19.3674 180.925 20.0434 181.482 20.7358C182.022 21.4283 182.383 22.0878 182.547 22.7308C182.71 23.3738 182.71 24.0662 182.547 24.8246C182.383 25.583 182.154 26.2755 181.842 26.8855C181.531 27.4955 181.122 28.221 180.581 29.0453L178.321 32.5241C177.83 33.2825 177.6 33.8266 177.649 34.1398C177.699 34.4531 177.961 34.7663 178.436 35.0796L178.812 35.3269L178.255 36.2007L174.914 34.0079L174.947 34.0409ZM177.682 29.8202L178.96 27.8582C173.62 25.039 170.344 24.5938 169.1 26.4898C168.412 27.5615 168.789 28.5837 170.279 29.5564C171.245 30.1994 172.457 30.4797 173.915 30.4138C175.373 30.3478 176.618 30.15 177.682 29.8037L177.682 29.8202Z" fill="black"/>
			<path d="M189.327 30.7263C187.771 30.9241 186.445 31.6166 185.331 32.8202C184.217 34.0237 183.628 35.4086 183.546 36.9749C183.464 38.5412 184.152 40.025 185.626 41.3934C186.723 42.4156 187.935 43.0092 189.245 43.1741C190.572 43.3389 191.604 43.0257 192.357 42.2013L193.864 43.6192C192.636 44.9547 191.113 45.4658 189.311 45.169C187.509 44.8722 185.871 44.0479 184.43 42.6794C182.645 41.0142 181.728 39.1182 181.662 37.0079C181.597 34.8975 182.317 33.018 183.84 31.3858C185.347 29.7536 187.149 28.8797 189.245 28.7973C191.342 28.6984 193.275 29.4898 195.06 31.1714C196.518 32.5234 197.451 34.1061 197.877 35.9032C198.303 37.7003 197.893 39.2501 196.665 40.5856L195.158 39.1677C195.912 38.3598 196.157 37.2882 195.879 35.9857C195.617 34.6667 194.929 33.5126 193.848 32.4739C192.374 31.1055 190.867 30.512 189.311 30.7098L189.327 30.7263Z" fill="black"/>
			<path d="M211.438 51.1048L203.085 49.3406L199.629 59.5132L198.123 57.5347L201.136 48.912L197.205 48.1041L193.258 51.1707L191.997 49.522L209.522 35.9531L210.783 37.6018L198.876 46.8346L209.932 49.1263L211.455 51.1213L211.438 51.1048Z" fill="black"/>
			<path d="M218.055 62.6953L219.087 64.987C219.3 65.4487 219.628 65.7125 220.086 65.7289C220.545 65.7619 221.216 65.597 222.117 65.2343L225.393 63.8989L225.999 65.2508L220.365 67.8228L222.068 71.6148L220.594 72.2908L218.891 68.5153L209.489 72.8019C208.949 73.0492 208.801 73.4449 209.047 73.989L210.144 76.4291L208.67 77.105L207.573 74.6649C207.049 73.5108 206.918 72.6865 207.147 72.1919C207.393 71.6973 208.015 71.2027 209.031 70.741L218.023 66.6357L216.565 63.3878L218.039 62.7118L218.055 62.6953Z" fill="black"/>
			<path d="M211.864 80.5516C212.896 78.6556 214.452 77.419 216.549 76.7925C218.645 76.1825 220.627 76.3968 222.51 77.419C224.378 78.4577 225.639 80.0735 226.277 82.2827C226.916 84.492 226.72 86.5364 225.688 88.4159C224.656 90.3119 223.1 91.5485 221.004 92.175C218.907 92.785 216.925 92.5707 215.042 91.532C213.158 90.4933 211.913 88.8776 211.275 86.6683C210.636 84.459 210.832 82.4146 211.864 80.5351L211.864 80.5516ZM221.61 79.0842C220.152 78.4412 218.661 78.3423 217.138 78.7875C215.615 79.2326 214.403 80.1229 213.518 81.4584C212.634 82.7938 212.454 84.3601 212.978 86.1902C213.502 88.0202 214.501 89.2403 215.959 89.8998C217.417 90.5428 218.907 90.6417 220.43 90.1965C221.953 89.7514 223.166 88.8611 224.05 87.5256C224.934 86.1902 225.115 84.6239 224.59 82.7938C224.066 80.9638 223.067 79.7437 221.626 79.0842L221.61 79.0842Z" fill="black"/>
			<path d="M229.881 104.328L229.979 106.851C229.995 107.362 230.208 107.724 230.634 107.922C231.044 108.12 231.732 108.219 232.698 108.219L236.236 108.219L236.301 109.703L230.11 109.967L230.274 114.121L228.652 114.187L228.489 110.033L218.186 110.461C217.597 110.494 217.318 110.791 217.335 111.385L217.449 114.056L215.828 114.121L215.713 111.451C215.664 110.181 215.844 109.373 216.254 108.994C216.663 108.615 217.417 108.417 218.547 108.367L228.423 107.955L228.276 104.394L229.897 104.328L229.881 104.328Z" fill="black"/>
			<path d="M217.499 118.536C219.153 117.168 221.069 116.607 223.247 116.822C225.426 117.053 227.178 117.992 228.538 119.658C229.897 121.323 230.454 123.301 230.225 125.593C229.995 127.885 229.046 129.698 227.391 131.067C225.737 132.435 223.821 132.996 221.642 132.765C219.464 132.55 217.712 131.594 216.352 129.929C215.009 128.264 214.436 126.285 214.665 123.994C214.894 121.718 215.844 119.888 217.499 118.536V118.536ZM227.064 120.861C225.95 119.707 224.607 119.064 223.035 118.899C221.462 118.734 220.005 119.097 218.678 120.004C217.368 120.894 216.614 122.295 216.418 124.191C216.221 126.087 216.68 127.604 217.793 128.742C218.907 129.896 220.25 130.539 221.823 130.704C223.395 130.869 224.853 130.506 226.179 129.599C227.49 128.709 228.243 127.308 228.44 125.411C228.636 123.515 228.177 121.999 227.064 120.861V120.861Z" fill="black"/>
			<path d="M214.452 136.69L208.212 134.893L208.785 132.898L227.768 138.372L227.277 140.087L224.296 139.229L224.214 139.51C224.296 139.576 224.41 139.675 224.541 139.79C224.689 139.905 224.918 140.169 225.246 140.581C225.573 140.977 225.835 141.406 226.048 141.867C226.261 142.313 226.392 142.906 226.474 143.632C226.54 144.357 226.474 145.082 226.261 145.824C225.688 147.852 224.509 149.287 222.723 150.078C220.938 150.886 218.989 150.968 216.844 150.358C214.747 149.748 213.142 148.627 212.045 146.962C210.947 145.313 210.652 143.533 211.209 141.62C211.422 140.845 211.75 140.153 212.192 139.576C212.618 138.999 213.06 138.554 213.519 138.273C213.977 137.993 214.403 137.762 214.829 137.581C215.255 137.399 215.615 137.3 215.877 137.284L216.287 137.218L216.369 136.938L214.452 136.69V136.69ZM223.886 140.911C222.953 139.642 221.741 138.801 220.25 138.372C218.76 137.943 217.286 138.009 215.828 138.603C214.37 139.197 213.42 140.252 212.978 141.834C212.536 143.401 212.782 144.753 213.715 145.94C214.649 147.127 215.877 147.918 217.417 148.363C218.956 148.808 220.414 148.775 221.823 148.281C223.231 147.786 224.165 146.748 224.607 145.198C225.049 143.615 224.82 142.197 223.886 140.928L223.886 140.911Z" fill="black"/>
			<path d="M204.396 186.153L202.709 188.016C202.365 188.395 202.266 188.807 202.43 189.236C202.578 189.665 203.003 190.225 203.691 190.901L206.214 193.407L205.215 194.512L200.629 190.324L197.844 193.391L196.648 192.286L199.433 189.22L191.8 182.229C191.358 181.833 190.949 181.85 190.556 182.295L188.77 184.273L187.575 183.169L189.36 181.19C190.212 180.251 190.9 179.789 191.456 179.822C192.013 179.855 192.701 180.234 193.52 180.993L200.825 187.686L203.216 185.048L204.412 186.153L204.396 186.153Z" fill="black"/>
			<path d="M177.141 192.367L179.303 195.005C179.303 194.312 179.336 193.603 179.418 192.845C179.5 192.086 179.778 191.18 180.237 190.092C180.696 189.003 181.318 188.13 182.104 187.487C183.152 186.613 184.152 186.201 185.085 186.25C186.035 186.3 186.854 186.745 187.542 187.586C188 188.146 188.295 188.773 188.394 189.465C188.492 190.158 188.492 190.817 188.344 191.427C188.197 192.054 187.935 192.729 187.542 193.471C187.149 194.213 186.756 194.84 186.379 195.367C186.002 195.895 185.544 196.472 184.97 197.115C184.414 197.758 184.004 198.187 183.742 198.434C183.48 198.681 183.185 198.978 182.825 199.324C183.677 200.33 184.61 200.924 185.626 201.088C186.641 201.253 187.837 200.775 189.196 199.638C190.392 198.648 191.063 197.593 191.194 196.505C191.325 195.417 191.031 194.428 190.294 193.521L191.882 192.202C193.012 193.57 193.405 195.071 193.062 196.703C192.718 198.335 191.8 199.769 190.326 201.006C189.425 201.764 188.557 202.308 187.738 202.622C186.92 202.951 186.182 203.083 185.527 203.05C184.872 203.017 184.217 202.803 183.546 202.424C182.874 202.045 182.301 201.616 181.793 201.121C181.302 200.643 180.745 200.017 180.122 199.275L177.502 196.076C176.929 195.367 176.486 195.005 176.175 194.955C175.864 194.906 175.487 195.054 175.045 195.433L174.701 195.714L174.046 194.906L177.109 192.35L177.141 192.367ZM180.335 196.258L181.826 198.071C186.084 193.785 187.493 190.768 186.051 189.02C185.233 188.031 184.152 188.097 182.792 189.234C181.891 189.976 181.269 191.048 180.892 192.466C180.515 193.884 180.335 195.153 180.352 196.274L180.335 196.258Z" fill="black"/>
			<path d="M168.314 215.086L171.131 206.975L161.549 202.226L163.695 200.973L171.802 205.079L173.096 201.254L170.59 196.918L172.375 195.879L183.513 215.103L181.728 216.142L174.161 203.067L170.492 213.817L168.33 215.086L168.314 215.086Z" fill="black"/>
			<path d="M153.704 206.713C152.344 207.24 151.345 207.982 150.674 208.922C150.019 209.862 149.789 210.834 150.002 211.807L148.086 212.549C147.726 210.851 148.02 209.334 148.97 208.032C149.92 206.729 151.296 205.723 153.065 205.031C155.342 204.141 157.422 204.141 159.305 205.031C161.189 205.905 162.532 207.389 163.334 209.483C164.137 211.56 164.137 213.571 163.334 215.484C162.548 217.396 161.009 218.798 158.732 219.688C156.373 220.611 154.342 220.661 152.639 219.853C150.936 219.045 149.707 217.66 148.954 215.698L148.626 214.874L161.271 209.961C160.648 208.493 159.666 207.405 158.355 206.696C157.045 206.004 155.473 206.004 153.671 206.696L153.704 206.713ZM158.11 218.039C159.698 217.413 160.779 216.522 161.336 215.335C161.893 214.148 162.04 212.879 161.795 211.51L151.214 215.632C151.476 216.143 151.804 216.605 152.197 217.017C152.59 217.429 153.065 217.776 153.622 218.089C154.179 218.386 154.85 218.55 155.636 218.55C156.423 218.55 157.242 218.386 158.126 218.039L158.11 218.039Z" fill="black"/>
			<path d="M119.996 212.383L120.766 221.55C120.897 223.033 121.143 224.072 121.503 224.682C121.864 225.292 122.421 225.572 123.174 225.507C124.091 225.424 124.828 224.929 125.352 224.023C125.893 223.116 126.089 221.83 125.975 220.181L125.27 211.938L127.334 211.756L128.104 220.923C128.235 222.407 128.481 223.446 128.841 224.056C129.201 224.666 129.758 224.946 130.512 224.88C131.445 224.798 132.182 224.286 132.723 223.33C133.247 222.374 133.443 221.022 133.296 219.274L132.624 211.295L134.672 211.113L135.9 225.605L134.131 225.754L133.902 222.951L133.607 222.951C133.198 225.292 132.018 226.545 130.069 226.71C129.054 226.793 128.251 226.578 127.694 226.067C127.138 225.556 126.712 224.732 126.433 223.594L126.253 223.594C125.844 225.919 124.664 227.172 122.715 227.337C121.389 227.452 120.422 227.056 119.833 226.133C119.243 225.226 118.866 223.742 118.686 221.714L117.916 212.548L119.964 212.366L119.996 212.383Z" fill="black"/>
			<path d="M107.778 212.829C106.337 212.68 105.108 212.878 104.093 213.406C103.077 213.95 102.439 214.708 102.193 215.664L100.146 215.45C100.604 213.768 101.538 212.565 102.979 211.839C104.42 211.114 106.091 210.85 107.975 211.048C110.399 211.295 112.249 212.252 113.527 213.9C114.804 215.549 115.345 217.478 115.116 219.704C114.886 221.929 113.986 223.71 112.413 225.062C110.841 226.414 108.843 226.958 106.419 226.711C103.896 226.447 102.062 225.557 100.915 224.056C99.7689 222.556 99.2939 220.759 99.5068 218.681L99.5887 217.791L113.085 219.193C113.183 217.593 112.823 216.175 111.971 214.955C111.119 213.735 109.727 213.026 107.794 212.812L107.778 212.829ZM106.582 224.93C108.286 225.111 109.645 224.798 110.677 224.007C111.709 223.215 112.413 222.144 112.806 220.808L101.521 219.621C101.521 220.198 101.603 220.759 101.767 221.303C101.931 221.847 102.193 222.375 102.553 222.902C102.914 223.43 103.438 223.875 104.142 224.238C104.846 224.6 105.649 224.831 106.599 224.93L106.582 224.93Z" fill="black"/>
			<path d="M78.7223 227.816L81.9816 219.54L82.9644 217.858L82.6859 217.742C82.6859 217.742 82.5058 217.924 82.3747 218.056C82.2437 218.188 81.9489 218.385 81.5067 218.666C81.0644 218.946 80.6058 219.177 80.1309 219.325C79.6559 219.49 79.0499 219.573 78.3292 219.556C77.6085 219.556 76.8715 219.408 76.1181 219.111C74.2837 218.385 73.0553 217.067 72.4329 215.187C71.7941 213.291 71.8924 211.329 72.6949 209.285C73.5139 207.207 74.7914 205.707 76.4948 204.767C78.2146 203.827 80.049 203.761 82.0144 204.536C82.7351 204.817 83.3411 205.196 83.8652 205.69C84.3893 206.169 84.766 206.647 84.9953 207.125C85.2246 207.603 85.4048 208.065 85.5358 208.51C85.6505 208.955 85.716 209.318 85.716 209.581L85.716 210.01L86.0108 210.125L87.1573 207.224L88.7952 207.883L80.655 228.574L78.7387 227.816L78.7223 227.816ZM81.3265 217.264C82.6695 216.456 83.6195 215.335 84.1928 213.885C84.766 212.434 84.8315 210.95 84.4057 209.433C83.9798 207.916 82.9971 206.861 81.4903 206.251C79.9835 205.657 78.624 205.773 77.3629 206.581C76.1017 207.389 75.1845 208.559 74.6112 210.043C74.0216 211.527 73.907 213.011 74.2673 214.462C74.6276 215.929 75.5448 216.951 77.0517 217.545C78.5749 218.155 79.9998 218.056 81.3429 217.248L81.3265 217.264Z" fill="black"/>
			<path d="M63.8339 195.65L62.0978 198.568C62.7366 198.337 63.4245 198.139 64.1615 197.958C64.8985 197.793 65.8485 197.744 67.0114 197.81C68.1743 197.876 69.2061 198.189 70.0906 198.716C71.2534 199.425 71.9741 200.233 72.2362 201.14C72.5146 202.047 72.3672 202.97 71.8103 203.91C71.4336 204.536 70.9423 205.015 70.3362 205.361C69.7302 205.691 69.1078 205.905 68.4855 205.971C67.8631 206.037 67.126 206.02 66.3071 205.888C65.4882 205.756 64.7511 205.608 64.1451 205.427C63.5227 205.245 62.8348 204.998 62.0486 204.685C61.2625 204.355 60.722 204.124 60.3944 203.959C60.0668 203.794 59.6901 203.613 59.2479 203.399C58.5928 204.553 58.3471 205.625 58.5272 206.647C58.7074 207.669 59.5591 208.642 61.0659 209.548C62.3926 210.356 63.6046 210.637 64.6692 210.406C65.7338 210.175 66.5692 209.548 67.1588 208.559L68.9277 209.631C68.0269 211.164 66.7493 212.038 65.0951 212.252C63.4572 212.467 61.803 212.087 60.1487 211.082C59.1332 210.472 58.3471 209.829 57.7738 209.153C57.2006 208.477 56.8239 207.834 56.6273 207.207C56.4472 206.581 56.4144 205.888 56.5454 205.113C56.6765 204.355 56.8894 203.646 57.1678 203.02C57.4462 202.377 57.8393 201.651 58.3471 200.827L60.4763 197.266C60.9349 196.491 61.1478 195.947 61.0987 195.633C61.0495 195.32 60.7711 195.023 60.2798 194.727L59.903 194.496L60.4272 193.605L63.8339 195.666L63.8339 195.65ZM61.2625 199.969L60.0668 201.981C65.5045 204.586 68.7967 204.916 69.9595 202.97C70.6147 201.866 70.1888 200.86 68.6656 199.937C67.6665 199.343 66.4545 199.096 64.9968 199.211C63.5555 199.326 62.3107 199.574 61.2461 199.953L61.2625 199.969Z" fill="black"/>
			<path d="M49.6173 199.39C51.1569 199.143 52.4672 198.401 53.5482 197.164C54.6292 195.928 55.1697 194.527 55.2024 192.944C55.2352 191.378 54.4982 189.927 52.9913 188.591C51.8612 187.602 50.6328 187.041 49.3225 186.91C48.0122 186.778 46.9804 187.14 46.2433 187.965L44.6873 186.596C45.883 185.228 47.3734 184.667 49.1915 184.898C51.0095 185.145 52.6637 185.92 54.1542 187.239C55.9886 188.855 56.9713 190.718 57.1024 192.828C57.2334 194.939 56.5619 196.835 55.1042 198.516C53.6465 200.198 51.8612 201.121 49.7647 201.27C47.6683 201.418 45.7028 200.693 43.8684 199.077C42.378 197.758 41.3788 196.225 40.9039 194.444C40.4289 192.664 40.7728 191.097 41.9685 189.729L43.5245 191.097C42.8038 191.938 42.5909 192.993 42.8857 194.312C43.1805 195.615 43.9012 196.769 45.0313 197.758C46.5381 199.093 48.0613 199.621 49.6173 199.39V199.39Z" fill="black"/>
			<path d="M26.9003 179.704L35.3025 181.221L38.4472 170.949L40.0196 172.878L37.2843 181.583L41.2316 182.276L45.0806 179.094L46.3909 180.71L29.2916 194.823L27.9813 193.223L39.6101 183.628L28.489 181.666L26.9003 179.72L26.9003 179.704Z" fill="black"/>
			<path d="M19.9558 168.346L18.8584 166.088C18.6291 165.626 18.3015 165.395 17.8429 165.362C17.3843 165.346 16.7128 165.527 15.8283 165.923L12.5854 167.357L11.9302 166.022L17.4826 163.268L15.6645 159.542L17.1222 158.817L18.9403 162.543L28.2106 157.959C28.7347 157.696 28.8821 157.3 28.6201 156.772L27.4572 154.382L28.9149 153.656L30.0778 156.047C30.6346 157.184 30.7984 158.009 30.5691 158.52C30.3398 159.031 29.7338 159.526 28.7347 160.02L19.8739 164.406L21.4298 167.604L19.9721 168.33L19.9558 168.346Z" fill="black"/>
			<path d="M25.5898 150.259C24.6235 152.171 23.0838 153.474 21.0201 154.15C18.94 154.826 16.9582 154.677 15.0419 153.688C13.1256 152.699 11.8317 151.132 11.1274 148.94C10.4232 146.763 10.5542 144.702 11.5369 142.79C12.5032 140.877 14.0428 139.575 16.1065 138.899C18.1866 138.223 20.1684 138.371 22.0684 139.361C23.9847 140.333 25.2786 141.916 25.9829 144.109C26.6871 146.285 26.5561 148.346 25.5734 150.259L25.5898 150.259ZM15.8936 152.006C17.3677 152.6 18.8582 152.666 20.3814 152.171C21.8882 151.676 23.0675 150.753 23.9192 149.401C24.7709 148.049 24.9019 146.467 24.3123 144.653C23.7226 142.839 22.7071 141.636 21.2331 141.042C19.759 140.449 18.2685 140.383 16.7453 140.877C15.2385 141.372 14.0592 142.295 13.2075 143.647C12.3558 144.999 12.2248 146.582 12.8144 148.396C13.3877 150.209 14.4195 151.413 15.8936 152.006Z" fill="black"/>
			<path d="M6.869 127.063L6.68883 124.54C6.65607 124.029 6.42677 123.683 6.00093 123.485C5.59146 123.304 4.88719 123.221 3.93723 123.254L0.399433 123.353L0.301166 121.869L6.4759 121.424L6.1811 117.286L7.80259 117.17L8.09739 121.309L18.3832 120.567C18.9728 120.517 19.2513 120.204 19.2021 119.611L19.0056 116.94L20.6271 116.824L20.8236 119.495C20.9055 120.765 20.7581 121.589 20.365 121.968C19.9719 122.347 19.2185 122.595 18.0884 122.661L8.2448 123.37L8.49049 126.914L6.869 127.03L6.869 127.063Z" fill="black"/>
			<path d="M18.8418 112.455C17.2203 113.873 15.3203 114.5 13.1584 114.335C10.98 114.17 9.19473 113.279 7.78617 111.647C6.3776 110.015 5.77161 108.053 5.9354 105.778C6.09919 103.486 6.98362 101.64 8.6051 100.222C10.2266 98.8038 12.1265 98.1773 14.3049 98.3422C16.4833 98.507 18.2685 99.3973 19.6771 101.013C21.0857 102.645 21.6916 104.607 21.5279 106.882C21.3641 109.174 20.4796 111.021 18.8582 112.439L18.8418 112.455ZM9.21113 110.427C10.3576 111.548 11.7171 112.158 13.3058 112.274C14.8945 112.389 16.3358 111.977 17.6134 111.037C18.9073 110.097 19.6116 108.68 19.7426 106.784C19.8736 104.888 19.3823 103.387 18.2358 102.266C17.0893 101.145 15.7298 100.535 14.1411 100.42C12.5524 100.304 11.111 100.716 9.8335 101.656C8.53959 102.596 7.8353 104.014 7.70427 105.91C7.57324 107.806 8.06462 109.306 9.21113 110.427Z" fill="black"/>
			<path d="M21.3151 94.2674L27.6045 95.8667L27.0967 97.8781L7.95011 92.9979L8.39234 91.2668L11.3896 92.0252L11.4551 91.7449C11.3732 91.6789 11.2586 91.5965 11.1112 91.4811C10.9638 91.3657 10.7345 91.1019 10.3905 90.7227C10.0466 90.327 9.76813 89.9148 9.55521 89.4697C9.34229 89.0245 9.17851 88.4475 9.08024 87.722C8.98197 86.9966 9.0311 86.2712 9.22764 85.5292C9.75176 83.4848 10.8819 82.0175 12.6344 81.1602C14.3869 80.3028 16.3524 80.1545 18.498 80.6985C20.6108 81.2426 22.2487 82.3143 23.3952 83.9465C24.5417 85.5622 24.8856 87.3428 24.3943 89.2553C24.1977 90.0467 23.8865 90.7392 23.4771 91.3327C23.0676 91.9262 22.6418 92.3714 22.1995 92.6682C21.7573 92.9649 21.3315 93.2122 20.9056 93.3936C20.4798 93.5749 20.1358 93.6904 19.8738 93.7233L19.4643 93.8058L19.3988 94.0861L21.3315 94.2674L21.3151 94.2674ZM11.7499 90.3435C12.7163 91.58 13.9447 92.3879 15.4515 92.7836C16.9584 93.1628 18.4325 93.0474 19.8574 92.4209C21.2987 91.7943 22.2159 90.6897 22.609 89.0905C23.0021 87.5242 22.7236 86.1558 21.7573 85.0181C20.791 83.8805 19.5298 83.1056 17.9902 82.71C16.4506 82.3143 14.9766 82.3967 13.5844 82.9243C12.1922 83.4684 11.2914 84.5235 10.8983 86.0898C10.4888 87.6891 10.7836 89.0905 11.7499 90.327L11.7499 90.3435Z" fill="black"/>
		</motion.g>
		<defs>
			<clipPath id="clip0_0_1">
				<rect width="228" height="236" fill="white" transform="translate(236.301 0.574219) rotate(90)"/>
			</clipPath>
		</defs>
	</Box>
}

export default BackToTop