import {Box} from '@chakra-ui/react'
import {motion} from 'framer-motion'
import React from 'react'
import {useMouse} from 'react-use';

const FollowCursor = ({children, elementRef, ...props}) => {
	const {elX, elY} = useMouse(elementRef);
	// console.log(docY, posY, elH, elY, elementRef.current.getBoundingClientRect().top)
	// @TODO unmount when not on position toi remove hover state from other palces
	return <Box
		as={motion.div}
		zIndex={10}
		position={'fixed'}
		initial={{
			left: '50%',
			top: '50%',
			opacity: 0,
			scale: 0.5,
		}}
		transition={{
			type: "spring",
			duration: 0.8,
			delay: 0.5,
			ease: [0, 0.71, 0.2, 1.01]
		}}
		animate={{
			left: elementRef.current.getBoundingClientRect().left + elX,
			top: elementRef.current.getBoundingClientRect().top + elY,
			opacity: 1, scale: 1
		}}
		{...props}
	>
		{children}
	</Box>
}

export default FollowCursor