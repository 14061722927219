import React, {useEffect, useState} from 'react'
import {useIntersection} from '../hooks'
import {IntersectionContext} from './index'

const IntersectionObserver = ({
																children,
																threshold = 0,
																reset = false, // if value set to true - observed element will reappear every time it shows up on the screen
																...props
															}) => {
	const [inView, setInView] = useState(false)
	const intersectionRef = React.useRef(null)
	const intersection = useIntersection(intersectionRef, {
		threshold
	})

	useEffect(() => {
		const inViewNow = intersection && intersection.intersectionRatio > 0
		if (inViewNow) {
			return setInView(inViewNow)
		} else if (reset) {
			return setInView(false)
		}
	}, [intersection, reset])

	return (
		<IntersectionContext.Provider value={{inView}}>
			<div ref={intersectionRef} {...props}>{children}</div>
		</IntersectionContext.Provider>
	)
}

export default IntersectionObserver