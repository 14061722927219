import {Box, Flex, Link} from '@chakra-ui/react'
import React, {useContext} from 'react'
import {BlopLink, Logo} from '../components/UI/atoms'
import {PageContext} from '../context'
import responsiveFontSize from '../@chakra-ui/gatsby-plugin/responsiveFontSize'
import {motion} from 'framer-motion'

const MenuItem = props => <BlopLink
	fontSize={responsiveFontSize(20)}
	lineHeight={responsiveFontSize(20)}
	textTransform={'uppercase'}
	{...props}
/>

const AnimateItem = ({children, hidden, animate = false, delay = 1, ...props}) => {
	const transition = {
		duration: 0.3 * delay,
		ease: [0.42, 0, 0.58, 1]
	}
	const variants = {
		hidden: {y: '-120%', opacity: 0, transition},
		show: {
			y: 0,
			opacity: 1,
			transition
		}
	}
	return <Box>
		<Box
			as={motion.div}
			initial={'hidden'}
			animate={animate ? 'show' : 'hidden'}
			whileInView={hidden ? 'hidden' : 'show'}
			exit={'hidden'}
			variants={variants}
			{...props}
		>
			{children}
		</Box>
	</Box>
}

const Header = ({hidden}) => {
	const pageContext = useContext(PageContext)
	const isHome = pageContext.location.pathname === '/'
	const isWorks = pageContext.location.pathname.includes('works')
	const isGotLight = pageContext.location.pathname.includes('gotta-light')
	return <React.Fragment>
		<Flex
			as={'header'}
			pt={responsiveFontSize(33)}
			alignItems={'center'}
			justifyContent={'space-between'}
		>
			<AnimateItem delay={1.5} hidden={hidden}><MenuItem href={'/gotta-light'} isActive={isGotLight}>GOTTA LIGHT ?</MenuItem></AnimateItem>
			{isHome && <AnimateItem hidden={hidden}><MenuItem delay={1.5} href={'#about'} onClick={() => {
				if (typeof window !== `undefined`) {
					window.scrollTo({top: window.pageYOffset + document.getElementById('about').getBoundingClientRect().top, behavior: 'smooth'})
				}
			}}>About</MenuItem></AnimateItem>}
			{!isHome && <AnimateItem hidden={hidden}><Link href={'/'} className={'nounderline'}><Logo
				maxW={responsiveFontSize(232)}
				px={responsiveFontSize(30)}
			/></Link></AnimateItem>}
			{isHome && <AnimateItem hidden={hidden}><MenuItem delay={1.5} href={'#projects'} onClick={() => {
				if (typeof window !== `undefined`) {
					window.scrollTo({top: window.pageYOffset + document.getElementById('projects').getBoundingClientRect().top, behavior: 'smooth'})
				}
			}}>Projects</MenuItem></AnimateItem>}
			{!isHome && <div style={{width: '8vw'}}/>}
		</Flex>
	</React.Fragment>
}

export default Header
