import {extendTheme} from '@chakra-ui/react'

const BREAKPOINTS = [20, 48, 75, 96, 121, 159]
const MIN_FONT = 0.85
const XS_MULTIPLIER = 0.552
const S_MULTIPLIER = 0.452
const M_MULTIPLIER = 0.501
const L_MULTIPLIER = 0.628
const MAX_FONT = 1.838

const theme = {
	breakpoints: {
		sm: '320px',
		md: '768px',
		lg: '1024px',
		xl: '1200px',
		'2xl': '1400px',
		'3xl': '2400px'
	},
	styles: {
		global: {
			'b, strong': {
				fontFamily: 'recoleta'
			},
			'html': {
				fontSize: '0.51rem',
				'@media screen and (min-width: 480px)': {
					fontSize: `calc(${MIN_FONT * XS_MULTIPLIER}rem + (${MAX_FONT * XS_MULTIPLIER} - ${MIN_FONT * XS_MULTIPLIER}) * (100vw - ${BREAKPOINTS[0]}rem) / (${BREAKPOINTS[5]} - ${BREAKPOINTS[0]}))`
				},
				'@media screen and (min-width: 1000px)': {
					fontSize: `calc(${MIN_FONT * S_MULTIPLIER}rem + (${MAX_FONT * S_MULTIPLIER} - ${MIN_FONT * S_MULTIPLIER}) * (100vw - ${BREAKPOINTS[0]}rem) / (${BREAKPOINTS[5]} - ${BREAKPOINTS[0]}))`
				},
				'@media screen and (min-width: 1400px)': {
					fontSize: `calc(${MIN_FONT * M_MULTIPLIER}rem + (${MAX_FONT * M_MULTIPLIER} - ${MIN_FONT * M_MULTIPLIER}) * (100vw - ${BREAKPOINTS[0]}rem) / (${BREAKPOINTS[5]} - ${BREAKPOINTS[0]}))`
				},
				'@media screen and (min-width: 2400px)': {
					fontSize: `calc(${MIN_FONT * L_MULTIPLIER}rem + (${MAX_FONT * L_MULTIPLIER} - ${MIN_FONT * L_MULTIPLIER}) * (100vw - ${BREAKPOINTS[0]}rem) / (${BREAKPOINTS[5]} - ${BREAKPOINTS[0]}))`
				},
				'@media screen and (min-width: 2550px)': {
					fontSize: '1.122rem'
				}
			},
			'body': {
				letterSpacing: '-0.02em'
			},
			'.p-wrapper:not(:last-child), p:not(:last-child)': {
				marginBottom: '1em'
			},
			'a.nounderline': {
				_hover: {
					textDecoration: 'none !important'
				},
				_focus: {
					textDecoration: 'none !important'
				}
			},
			'a:not(.nounderline), .underline': {
				position: 'relative',
				whiteSpace: 'nowrap',
				'&.active': {
					textDecoration: 'none !important',
					_before: {
						left: '-5%',
						visibility: 'visible',
						transform: 'scaleX(1.1)'
					}
				},
				_hover: {
					textDecoration: 'none !important',
					_before: {
						left: '-5%',
						visibility: 'visible',
						transform: 'scaleX(1.1)'
					}
				},
				_focus: {
					textDecoration: 'none !important',
					_before: {
						left: '-5%',
						visibility: 'visible',
						transform: 'scaleX(1.1)'
					}
				},
				_before: {
					content: '\'\'',
					position: 'absolute',
					width: '100%',
					height: '2px',
					bottom: '-4px',
					left: '-5%',
					transform: 'scaleX(0)',
					backgroundColor: 'black',
					visibility: 'hidden',
					transformOrigin: '0% 100%',
					transition: 'all 0.3s ease-in-out'
				}
			}
		}
	},
	fonts: {
		steinbeck: `'Steinbeck', sans-serif`,
		recoleta: `'Recoleta', sans-serif`,
		'cf-asty-std': `'CF Asty Std', sans-serif`,
		heading: `recoleta`,
		body: `steinbeck`
	},
	space: {
		'xs': '.25rem',
		'sm': '.75rem',
		'md': '1rem',
		'lg': '1.5rem',
		'xl': '3rem',
		'2xl': '5rem',
		'120': '12rem',
		'10vw': '10vw',
		'20vw': '20vw'
	},
	fontSizes: {
		'3xs': '0.45rem',
		'2xs': '0.625rem',
		xs: '0.75rem',
		sm: '0.875rem',
		md: '1rem',
		lg: '1.125rem',
		xl: '1.25rem',
		'2xl': '1.5rem',
		'3xl': '1.875rem',
		'4xl': '2.25rem',
		'5xl': '3rem',
		'6xl': '3.75rem',
		'7xl': '4.5rem',
		'8xl': '6rem',
		'9xl': '8rem',
		'r30': 'clamp(1.5rem, 1vw + 1rem, 2.375rem)',
		'r20': 'clamp(1rem, 1vw + 1rem, 3.333rem);',
		's57': {
			'sm': '1.184rem',
			'md': '1.933rem',
			'lg': '2.375rem',
			'xl': '3.111rem',
			'2xl': '3.5625rem'
		},
		s33: '2rem'
	},
	components: {
		Container: {
			sizes: {
				full: {
					maxW: 'full',
					paddingX: '5.572rem' // from 60px
				}
			},
			defaultProps: {
				size: 'full'
			}
		}
	}
}

export default extendTheme(theme)
