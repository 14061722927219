import { Box } from '@chakra-ui/react'
import React from 'react'

const Ratio = ({ ratio = '1:1', children, ...props}) => {
  const split = ratio.split(':')
  const width = split.pop()
  const height = split.pop()
  return <Box position={'relative'} pt={width / height * 100 + '%'} {...props}>
    <Box sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 }}>
      {children}
    </Box>
  </Box>
}

export default Ratio