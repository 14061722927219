import {Box, GridItem, Link, SimpleGrid, Text} from '@chakra-ui/react'
import {PortableText} from '@portabletext/react'
import urlBuilder from '@sanity/image-url'
import React from 'react'
import FadeIn from '../UI/atoms/FadeIn'
import {ImagePopover} from '../index'
import responsiveFontSize from '../../@chakra-ui/gatsby-plugin/responsiveFontSize'
import {SanityImage} from '../../gatsby-source-sanity'

const SampleImageComponent = ({value, isInline}) => {
	return (
		<img
			src={urlBuilder()
			.image(value)
			.width(isInline ? 100 : 800)
			.fit('max')
			.auto('format')
			.url()}
			alt={value.alt || ' '}
			loading='lazy'
			style={{
				display: isInline ? 'inline-block' : 'block'
			}}
		/>
	)
}

const serializers = {
	types: {
		image: SampleImageComponent,
		twoColContent: ({value, children}) => <FadeIn className={'p-wrapper'}>
			<SimpleGrid columns={[1, 1, 1, 2]} gap={responsiveFontSize(100)}>
				<div>{value.banner && <SanityImage image={value.banner} border={'2px solid black'}/>}</div>
				<div><ModuleSerializer blocks={value.content}/></div>
			</SimpleGrid>
		</FadeIn>,
	},
	block: {
		normal: ({children}) => <FadeIn className={'p-wrapper'}>
			<p>{children}</p>
		</FadeIn>,
		small: ({children}) => <FadeIn className={'p-wrapper'}>
			<Text as={'p'} fontSize={responsiveFontSize(20)} lineHeight={responsiveFontSize(30)}>{children}</Text>
		</FadeIn>,
	},
	marks: {
		underline: ({value, children}) => <Text as={'span'} className={'underline active'}>{children}</Text>,
		link: ({value, children}) => <Link
			href={value.href}
			isExternal={value.href.includes('shampoofilms.com')}
			pb={responsiveFontSize(5)}
		>
			{children}
		</Link>,
		imagePopover: ({value, children, ...props}) => <ImagePopover
			image={value.Image}
			href={value.Url}>{children}</ImagePopover>
	}
}

const ModuleSerializer = ({blocks, ...props}) => <PortableText
	value={blocks}
	components={serializers}
	onMissingComponent={(message, options) => {
		console.warn(message, {
			type: options.type,
			nodeType: options.nodeType
		})
	}}
/>

export default ModuleSerializer
