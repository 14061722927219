import React from 'react'
import {Global} from '@emotion/react'

const Fonts = () => (
	<Global
		styles={`
    @font-face {
        font-family: 'CF Asty Std';
        src: url('../fonts/CFAstyStd-Medium.woff2') format('woff2'),
            url('../fonts/CFAstyStd-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Steinbeck';
        src: url('../fonts/Steinbeck-Regular.woff2') format('woff2'),
            url('../fonts/Steinbeck-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Recoleta';
        src: url('../fonts/Recoleta-SemiBold.woff2') format('woff2'),
            url('../fonts/Recoleta-SemiBold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'CF Asty Std';
        src: url('../fonts/CFAstyStd-Book.woff2') format('woff2'),
            url('../fonts/CFAstyStd-Book.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }`}
	/>
)

export default Fonts
