import {graphql, useStaticQuery} from 'gatsby'

const useSettings = (lang = 'en_GB') => {
	const {allSanityHomepage} = useStaticQuery(
		graphql`
      query {
        allSanityHomepage {
          nodes {
            _rawHeaderIntro(resolveReferences: {maxDepth: 10})
            _rawHeaderContent(resolveReferences: {maxDepth: 10})
            header_contact_prompt
            team_title
            team_intro
            _rawTeamDefaultText(resolveReferences: {maxDepth: 10})
            _rawTeamDescription(resolveReferences: {maxDepth: 10})
            projects_title
            _rawProjectsContent(resolveReferences: {maxDepth: 10})
            _rawProjectsDescription(resolveReferences: {maxDepth: 10})
            _rawProjectsOutro(resolveReferences: {maxDepth: 10})
            works_title
            works_subtitle
            works {
              title
              banner {
                asset {
                  url
                }
              }
              url
            }
          }
        }
      }
		`)
	// const homepage = allSanityHomepage.nodes.find(node => node.i18n_lang === lang)
	const homepage = allSanityHomepage.nodes[0]
	if (!homepage) {
		console.error('No homepage found!', lang)
	}
	return homepage
}

export default useSettings
