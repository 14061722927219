import {Box, Flex, GridItem, Hide, Link, SimpleGrid, Text} from '@chakra-ui/react'
import React, {useRef} from 'react'
import {useHomepage, useSettings} from '../hooks'
import {ModuleSerializer} from '../components'
import FadeIn from '../components/UI/atoms/FadeIn'
import {Logo} from '../components/UI/atoms'
import responsiveFontSize from '../@chakra-ui/gatsby-plugin/responsiveFontSize'

const ShampooIntro = () => {
	const showElementRef = useRef(null)
	const {email, social} = useSettings()
	const {
		_rawHeaderIntro,
		_rawHeaderContent,
		header_contact_prompt
	} = useHomepage()
	return <React.Fragment>
		<Box
			pt={responsiveFontSize(20)}
			sx={{
				display: 'flex',
				flexDirection: 'column'
			}}
			h={['35vh', '40vh', '45vh', '50vh', '80vh','80vh',`68vh`]}
		>
			<Box position={'sticky'} top={'50px'} w={'full'}>
				<FadeIn delayOrder={0.2}>
					<Logo w={'full'}/>
				</FadeIn>
			</Box>
		</Box>
		<SimpleGrid columns={[1, 1, 1, 1, 5]} gap={responsiveFontSize(87)}>
			<GridItem colSpan={[1, 1, 1, 1, 2]}>
				<Box position={'sticky'} top={0} fontSize={responsiveFontSize(30)} lineHeight={responsiveFontSize(44)}
						 textAlign={'justify'}>
					<Box ref={showElementRef}>
						<FadeIn>
							<ModuleSerializer blocks={_rawHeaderIntro}/>
						</FadeIn>
					</Box>
					<br/>
					<FadeIn>
						<ModuleSerializer blocks={_rawHeaderContent}/>
					</FadeIn>
				</Box>
			</GridItem>
			<GridItem colSpan={[1, 1, 1, 1, 3]} position={'relative'}>
				<Hide below={'xl'}>
					<Box h={'full'}/>
					<Box bottom={responsiveFontSize(33)} fontSize={responsiveFontSize(25)}>
						<Box position={'absolute'} top={0} right={0}>
							<Flex direction={'column'} alignItems={'end'}>
								<Flex
									alignItems={'center'}
									mb={responsiveFontSize(30)}
									position={{base:'absolute','3xl': 'relative'}}
									right={0}
									bottom={{base:'100%','3xl': 0}}
								>
									{social && social.map(({url, platform}, index) => <FadeIn
										key={'social-' + index}
										delayOrder={index * 0.25}
									>
										<Link
											display={'block'}
											href={url}
											target={'_blank'}
											rel={'noopener follow'}
											ml={responsiveFontSize(20)}
										>
											({platform.toUpperCase()})
										</Link>
									</FadeIn>)}
								</Flex>
								<FadeIn delayOrder={3 * 0.25}>
									<Text as={'span'}>{header_contact_prompt}</Text>
								</FadeIn>
								<FadeIn delayOrder={4 * 0.25}>
									{email &&
									<Link display={'block'} href={'mailto:' + email} sx={{textTransform: 'uppercase'}}>{email}</Link>}
								</FadeIn>
							</Flex>
						</Box>
					</Box>
				</Hide>
			</GridItem>
		</SimpleGrid>
	</React.Fragment>
}

export default ShampooIntro
