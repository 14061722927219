import { motion } from 'framer-motion';
import React, { useContext, useMemo, useState, useEffect } from "react";
import {IntersectionContext, IntersectionObserver} from '../../../context'

const FadeInAnimation = ({
								 children,
								 yOffset = 50, // y initial possition
								 easing = [0.42, 0, 0.58, 1], // [number, number, number, number] | "linear" | "easeIn" |
								 //  "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" |
								 // "backInOut" | "anticipate" | EasingFunction;
								 delayOrder, // order of appearance
								 ...rest
							 }) => {
	const { inView } = useContext(IntersectionContext);
	const [delay, setDelay] = useState(0.25);

	const offset = 0.3;

	useEffect(() => {
		if (delayOrder) return setDelay(delayOrder * offset);
	}, [delayOrder, offset]);

	const transition = useMemo(
		() => ({
			duration: 0.4,
			delay,
			ease: easing
		}),
		[delay, easing]
	);

	const variants = {
		hidden: { y: yOffset, opacity: 0, transition },
		show: {
			y: 0,
			x: 0,
			opacity: 1,
			transition
		}
	};

	return (
		<motion.div
			initial="hidden"
			animate={inView ? "show" : "hidden"}
			exit="hidden"
			variants={variants}
			{...rest}
		>
			{children}
		</motion.div>
	);
};

const FadeIn = ({className, ...props}) => <IntersectionObserver threshold={0.4} className={className}>
	<FadeInAnimation {...props}/>
</IntersectionObserver>

export default FadeIn
