import {Box} from '@chakra-ui/react'
import React from 'react'

const ArrowDown = props => <Box {...props}
	as={'svg'}
	width="187" height="187" viewBox="0 0 187 187" fill="none" xmlns="http://www.w3.org/2000/svg"
	_hover={{
		path: {
			fill: '#000'
		}
	}}>
	<path fillRule="evenodd" clipRule="evenodd"
				d="M89.9936 45.1609L89.9936 132.331L47.9885 90.326L43.5197 94.7948L93.1536 144.429L142.787 94.7948L138.319 90.326L96.3135 132.331L96.3135 45.1609L89.9936 45.1609Z"
				stroke="black" strokeWidth="2"/>
	<circle cx="93.5" cy="93.5" r="92.5" stroke="black" strokeWidth="2"/>
</Box>

export default ArrowDown