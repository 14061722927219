import React from 'react'
import {Box, Link} from '@chakra-ui/react'
import {motion} from 'framer-motion'
import responsiveFontSize from '../../../@chakra-ui/gatsby-plugin/responsiveFontSize'

const BlopLink = ({children, isActive = false, ...props}) => {
	return <Link
		as={motion.a}
		initial={isActive ? 'hover' : 'rest'}
		whileHover="hover"
		animate={isActive ? 'hover' : 'rest'}
		position={'relative'}
		{...props}
		py={responsiveFontSize(5)}
		className={'nounderline'}
	>
		<Box
			as={motion.div} variants={{
			rest: {
				opacity: 0,
				scale: 1.3,
				display: 'none'
			},
			hover: {
				opacity: 1,
				scale: 1,
				display: 'block'
			}
		}}
			border={'2px solid'} position={'absolute'}
			left={{base: '-1em', md: '-2em'}} right={{base: '-1em', md: '-2em'}}
			top={'-'+responsiveFontSize(10)} bottom={'-'+responsiveFontSize(13)}
			borderRadius={'100%'}/>
		{children}
	</Link>
}

export default BlopLink
