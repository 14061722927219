import React, {useRef, useState} from 'react'
import {Box, Link, Portal} from '@chakra-ui/react'
import {FollowCursor} from './UI/molecules'
import {SanityImage} from '../gatsby-source-sanity'

const ImagePopover = ({image, href, children}) => {
	const contentRef = useRef()
	const [hover, setHover] = useState(false)
	const isHorizontal = image.asset.width && image.asset.height && image.asset.width > image.asset.height
	return <React.Fragment>
		<Box ref={contentRef} as={'span'} cursor={'pointer'}
				 onMouseEnter={() => setHover(true)}
				 onMouseLeave={() => setHover(false)}
		>
			{href && <Link href={href} isExternal className={'nounderline'}>{children}</Link>}
			{!href && children}
		</Box>
		<Portal>
			{hover && <FollowCursor elementRef={contentRef}>
				<Box
					zIndex={10}
					maxW={isHorizontal ? '20vw' : '13vw'}
					position={'fixed'} pointerEvents={'none'}>
					<SanityImage image={image}/>
				</Box>
			</FollowCursor>}
		</Portal>
	</React.Fragment>
}

export default ImagePopover